import { createRouter, createWebHistory } from "vue-router";
import SplashScreen from "../components/SplashScreen.vue";
import LoginView from "../views/Auth/Login.vue";

const routes = [
  {
    path: "/",
    name: "splash-screen",
    component: SplashScreen,
    beforeEnter(to, from, next) {
      setTimeout(() => {
        next("/home");
      }, 3000);
    },
  },
  {
    path: "/login",
    name: "login-view",
    component: LoginView,
  },
  {
    path: "/register",
    name: "register-view",
    component: () => import("../views/Auth/Register.vue"),
  },
  {
    path: "/home",
    name: "HomeView",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/appointment",
    name: "appointment",
    component: () => import("../views/Appointment/Appointment.vue"),
  },
  {
    path: "/detail-appointment/:id",
    name: "detail-appointment",
    component: () => import("../views/Appointment/Detail-Appointment.vue"),
  },
  {
    path: "/homecare",
    name: "homecare-view",
    component: () => import("../views/Homecare/Homecare.vue"),
  },
  {
    path: "/booking-homecare/:id",
    name: "booking-homecare",
    component: () => import("../views/Homecare/Booking-Homecare.vue"),
  },
  {
    path: "/gds-tracker",
    name: "gds-view",
    component: () => import("../views/GDS/Gds-Tracker.vue"),
  },
  {
    path: "/history-gds/:patientId",
    name: "history-gds",
    component: () => import("../views/GDS/History-GDS.vue"),
  },
  {
    path: "/riwayat-pemesanan",
    name: "history-view",
    component: () => import("../views/History/Order-History.vue"),
  },
  {
    path: "/detail-pemesanan/:id",
    name: "order-detail",
    component: () => import("../views/History/Order-Details.vue"),
  },
  {
    path: "/notifikasi",
    name: "notification-view",
    component: () => import("../views/Notification/Notification.vue"),
  },
  {
    path: "/profil",
    name: "profile-view",
    component: () => import("../views/Profile/ProfileView.vue"),
  },
  {
    path: "/pengaturan",
    name: "setting-view",
    component: () => import("../views/Profile/Setting.vue"),
  },
  {
    path: "/support",
    name: "support-view",
    component: () => import("../views/Profile/Support.vue"),
  },
  {
    path: "/faq",
    name: "faq-view",
    component: () => import("../views/Profile/FAQ.vue"),
  },
  {
    path: "/riwayat-luka/:mr/:dob",
    name: "injury-view",
    component: () => import("../views/Profile/Injury-History.vue"),
  },
  {
    path: "/detail-luka",
    name: "detail-injury",
    component: () => import("../views/Profile/Detail-Injury.vue"),
  },
  {
    path: "/syarat-dan-ketentuan",
    name: "conditions-view",
    component: () => import("../views/Profile/Conditions.vue"),
  },
  {
    path: "/artikel",
    name: "article-view",
    component: () => import("../views/Article/Article.vue"),
  },
  {
    path: "/detail-artikel/:slug",
    name: "detail-article",
    props: true,
    component: () => import("../views/Article/Detail-Article.vue"),
  },
  {
    path: "/edukasi",
    name: "education-view",
    component: () => import("../views/Education/Education.vue"),
  },
  {
    path: "/detail-edukasi/:slug",
    name: "detail-education",
    props: true,
    component: () => import("../views/Education/Detail-Education.vue"),
  },
  {
    path: "/buku-panduan",
    name: "buku-panduan",
    component: () => import("../views/GuideBook/Guide-Book.vue"),
  },
  {
    path: "/ricka-pasien",
    name: "ricka-pasien",
    component: () => import("../views/Ricka/Ricka-View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
