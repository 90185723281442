<template>
  <div class="login-wrapper">
    <div class="login-container" v-if="!isLoading">
      <div class="back-btn-text" @click="backBtn">Kembali</div>
      <div class="logo">
        <img src="@/assets/icon_rk_new.png" alt="RumahKita Logo" />
      </div>
      <h2>Login atau Register</h2>
      <form @submit.prevent="submitForm">
        <input type="text" v-model="phoneNumber" placeholder="Nomor Ponsel" inputmode="numeric" />
        <button type="submit" @click.prevent="generateOtp">Lanjut</button>
      </form>
      <p>
        Dengan login atau register akun anda menyetujui
        <a href="#">Syarat & Ketentuan</a> dan <a href="#">Kebijakan Privasi</a> RumahKita.
      </p>
    </div>
    <div v-if="isBottomSheetOpen && !isLoading" class="bottom-sheet-overlay">
      <div class="bottom-sheet-content">
        <div class="bottom-sheet-header">
          <h5 class="bottom-sheet-title">Verifikasi nomor ponsel</h5>
        </div>
        <div class="bottom-sheet-body">
          <p>Masukan Kode OTP yang telah terkirim ke:</p>
          <p>{{ phoneNumber }}</p>
          <div class="otp-inputs">
            <input
              v-for="(otp, index) in otpInputs"
              :key="index"
              v-model="otpInputs[index]"
              type="text"
              maxlength="1"
              @input="handleOtpInput($event, index)"
              :class="{ error: isOtpError }"
              ref="otpInputs"
              inputmode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <p v-if="isOtpError" class="error-message">{{ errMsg }}</p>
          <p v-if="countdown > 0">Kirimkan kode OTP baru ({{ countdown }} detik)</p>
          <p v-else><a href="#" @click.prevent="resendOtp">Kirimkan kode OTP baru</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import storage from "@/controllers/storage";

export default {
  name: "login-view",
  data() {
    return {
      phoneNumber: "",
      isBottomSheetOpen: false,
      otpInputs: ["", "", "", ""],
      isOtpError: false,
      errMsg: "",
      isLoading: false,
      deviceInfo: {
        browser: "",
        os: "",
        platform: "",
        ip: "",
      },
      countdown: 60,
      countdownInterval: null,
    };
  },
  methods: {
    async generateOtp() {
      await this.getDeviceInfo();
      try {
        const device_id = `${this.deviceInfo.browser}|${this.deviceInfo.os}|${this.deviceInfo.platform}|${this.deviceInfo.ip}`;
        const response = await axios.post(`${process.env.VUE_APP_GATEWAY}generate-otp`, {
          phone: this.phoneNumber,
          device_id: device_id,
        });
        if (response.data.status == 200) {
          this.openBottomSheet();
          this.startCountdown();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error generating OTP:", error);
      }
    },
    async verifyOtp() {
      await this.getDeviceInfo();
      try {
        const device_id = `${this.deviceInfo.browser}|${this.deviceInfo.os}|${this.deviceInfo.platform}|${this.deviceInfo.ip}`;
        const response = await axios.post(`${process.env.VUE_APP_GATEWAY}receive-otp`, {
          phone: this.phoneNumber,
          device_id: device_id,
          otp_code: this.otpInputs.join(""),
        });
        if (response.data.status == 200) {
          if (response.data.user_status == 1) {
            var datarequest = {
              token: response.data.access_token,
              memberId: response.data.user_code,
            };
            storage.setLocalStorage(datarequest);
            this.$router.push({ name: "HomeView" });
          } else {
            this.$router.push({ name: "register-view" });
          }
        } else if (response.data.status == 500) {
          alert(response.data.message);
          this.$router.push({ name: "HomeView" });
        } else {
          this.isOtpError = true;
          this.errMsg = response.data.message;
        }
      } catch (error) {
        this.isOtpError = true;
        this.errMsg = "Failed to verify OTP";
      }
    },
    openBottomSheet() {
      this.isBottomSheetOpen = true;
    },
    closeBottomSheet() {
      this.isBottomSheetOpen = false;
    },
    handleOtpInput(event, index) {
      const value = event.target.value;
      if (value.length === 1) {
        if (index < this.otpInputs.length - 1) {
          this.$refs.otpInputs[index + 1].focus();
        } else {
          this.verifyOtp();
        }
      } else if (value.length === 0) {
        if (index > 0) {
          this.$refs.otpInputs[index - 1].focus();
        }
      }
    },
    async getDeviceInfo() {
      this.deviceInfo.browser = navigator.userAgent;
      this.deviceInfo.os = this.getOS();
      this.deviceInfo.platform = navigator.platform;
      this.deviceInfo.ip = await this.getIP();
    },
    getOS() {
      let os = "";
      const userAgent = navigator.userAgent;

      if (userAgent.indexOf("Win") !== -1) os = "Windows";
      else if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
      else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
      else if (userAgent.indexOf("Android") !== -1) os = "Android";
      else if (userAgent.indexOf("iOS") !== -1) os = "iOS";

      return os;
    },
    async getIP() {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        return response.data.ip;
      } catch (error) {
        console.error("Error getting IP:", error);
        return "Unknown";
      }
    },
    startCountdown() {
      this.countdown = 60;
      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    async resendOtp() {
      await this.generateOtp();
      this.isOtpError = false;
    },

    async backBtn() {
      this.$router.go(-1);
    },
  },

  async mounted() {
    const session = storage.getLocalStorage();
    if (session.token) {
      this.$router.push({ name: "HomeView" });
    } else {
      await this.getDeviceInfo();
      this.$nextTick(() => {
        if (this.$refs.otpInputs && this.$refs.otpInputs.length > 0) {
          this.$refs.otpInputs[0].focus();
        }
      });
    }
  },
};
</script>

<style scoped>
@import url("./css/login.css");
</style>
